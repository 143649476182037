import { loading } from '@/tool/loading'

export default {
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async handleLoading(fn, customLoading) {
      const loadingField = customLoading || 'loading'
      this[loadingField] = true
      let result

      try {
        result = result = await loading(fn, false)
      } catch (err) {
        result = Promise.reject(err)
      }

      this[loadingField] = false
      return result
    }
  }
}
