import request from '@/tool/axios'

// get
// 获取酒店列表
export function list() {
  return request({
    url: '/hotel/hotel/getallhotel',
    method: 'get'
  })
}

// 第三方酒店查询
export const hotelSearch = data => request.post('/v1/apihotel/search', data)

// 获取酒店价格策略、库存
export function priceStrategy(data) {
  return request({
    url: '/hotel/pricestrategycalendarprice/getbyhotel',
    data
  })
}

// 修改酒店价格策略的库存、可售
export function updatePriceStrategy(data) {
  return request({
    url: '/hotel/pricestrategycalendarprice/updatebasisprice',
    data
  })
}

// post
export function supplierInfo(data) {
  return request({
    url: '/tenant/supplier/shortinfo',
    data
  })
}

// 获取团房申请信息
export const getGroupBookingApplicationOrder = id => request.get('/v1/hotelgroupbooking/getapplicationform', { params: {id} })

// 获取团房申请信息(SaaS)
export const getGroupBookingApplicationOrderForSaaS = params => request.get('/v1/groupbookingapplicationorder/get', { params })

// 团房申请拒单
export const rejectGroupBookingApplicationOrder = id => request.post('/v1/groupbookingapplicationorder/reject', { id })

// 回复团房申请
export const replyGroupBookingApplicationOrder = data => request.post('/v1/groupbookingapplicationorder/reply', data)
