<template>
  <div :class="styles.box">
    <div v-if="$slots['head-left'] || title" class="flex-between-center mb20">
      <span :class="styles.title">{{ title }}</span>
      <div>
        <slot name="head-left" />
      </div>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
import styles from './index.module.scss'

export default {
  name: 'GroupHotelReplyBox',
  props: {
    title: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      styles
    }
  }
}
</script>
