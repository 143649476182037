<template>
  <div>
    <hotel-list v-model="list" add title="推荐酒店">
      <van-button
        slot="head-left"
        round
        type="primary"
        size="small"
        class="width-200"
        @click="handleAdd"
      >
        添加
      </van-button>
    </hotel-list>

    <box>
      <van-field label="价格策略数量">
        <van-stepper slot="input" v-model="form.priceStrategyCount" integer min="1" max="9" />
      </van-field>
    </box>

    <box title="备注：">
      <van-field
        v-model="form.remark"
        show-word-limit
        autosize
        rows="5"
        type="textarea"
        maxlength="500"
        placeholder="备注，0~500字"
      />
    </box>

    <div class="text-center">
      <van-button
        round
        :loading="loading"
        type="primary"
        class="ml40 width-300"
        @click="handleSubmit"
      >
        提交
      </van-button>
    </div>
  </div>
</template>

<script>
import mixinLoading from '@/mixin/loading'
import { replyGroupBookingApplicationOrder } from '@/api/hotel'
import Box from '../components/box'
import HotelList from '../components/hotel-list'

export default {
  name: 'GroupHotelReply',
  components: {
    Box,
    HotelList
  },
  mixins: [mixinLoading],
  data() {
    return {
      list: [],
      form: {
        priceStrategyCount: 1,
        remark: undefined
      }
    }
  },
  created() {
    this.list = this.$pageInteraction.get() || []
  },
  methods: {
    handleAdd() {
      const { id, agencyId } = this.$route.query
      this.$push(
        'groupHotelSearch',
        { id, agencyId },
        {
          pageData: this.list
        }
      )
    },
    async handleSubmit() {
      const { id } = this.$route.query

      if (!this.list.length) {
        this.$toast('请选择推荐酒店')
        return
      }

      await this.handleLoading(
        replyGroupBookingApplicationOrder({
          ...this.form,
          id,
          suggestItems: this.list.map(e => ({
            hotelId: e.id,
            hotelName: e.zhName,
            cityCode: e.cityCode,
            cityName: e.cityName
          }))
        })
      )

      this.$toast('提交成功')
      this.$router.back()
    }
  }
}
</script>
