<template>
  <box :title="title">
    <slot slot="head-left" name="head-left" />
    <ul :class="styles.list">
      <template v-if="value.length">
        <li
          v-for="(item, index) in value.slice(0, 3)"
          :key="item.id"
          class="flex-between-center ellipsis"
        >
          <span>{{ item.zhName }}</span>
          <van-icon v-if="remove" name="cross" @click="handleRemove(index, item)" />
        </li>
      </template>
      <div v-else :class="['flex-center-center height-full', styles.empty]">请选择需要添加的酒店</div>
    </ul>
  </box>
</template>

<script>
import Box from '../box'
import styles from './index.module.scss'

export default {
  name: 'GroupHotelList',
  components: {
    Box
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: undefined
    },
    add: {
      type: Boolean,
      default: false
    },
    remove: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      styles
    }
  },
  methods: {
    handleRemove(index) {
      const list = [...this.value]
      list.splice(index, 1)
      this.$emit('input', list)
    }
  }
}
</script>
